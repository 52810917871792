<template>
  <!--服务申请记录-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="88px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <!--<el-col :lg="6" :md="12">-->
                <!--  <el-form-item label="服务商:">-->
                <!--    <el-input-->
                <!--        clearable-->
                <!--        v-model="where.facilitator_name"-->
                <!--        placeholder="请输入服务商"/>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :lg="6" :md="12">
                  <el-form-item label="时间:">
                    <el-date-picker
                        v-model="where.time"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12" style="display: flex;justify-content: center;">
                  <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                  </el-button>
                  <el-button @click="empty">清空</el-button>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <!--<div class="custom" style="margin-left: 20px">-->
              <!--  <el-button class="button" type="primary">-->
              <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
              <!--    <i class="el-icon-tianjia" />-->
              <!--    <span style="vertical-align: middle" @click="openEdit(null)">添加</span>-->
              <!--  </el-button>-->
              <!--  <el-button class="custom-button">-->
              <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
              <!--    <i class="el-icon-devops" />-->
              <!--    <span style="vertical-align: middle">批量导入</span>-->
              <!--  </el-button>-->
              <!--</div>-->
            </div>
            <!--<div>-->
            <!--  <el-button>导出数据</el-button>-->
            <!--  <el-button @click="ListSetup()">列表设置</el-button>-->
            <!--</div>-->
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
                ref="myTable"
                :columns="columns"
                :tableData="List"
                :cellStyle="cellStyle"
                :headerCellStyle="headerCellStyle"
                :rowClassName="rowClassName"
                :total="total"
                :customsFromWhere="where"
                :loading="loading"
                @select="select"
                @ChangeSize="ChangeSize"
                @currentChange="currentChange"
            >
              <template v-slot:paid_status= "{ scope }">
                <div v-if="scope.row.paid_status == '已支付'">
                  <span style="color: #25eb61;">{{scope.row.paid_status}}</span>
                </div>
                <div v-else>
                  <span style="color: red;">{{scope.row.paid_status}}</span>
                </div>
              </template>

              <!--<template v-slot:serial_number= "{ scope }">-->
              <!--  <span v-if="scope.row.serial_number == ''" style="color: #0000FF;cursor: pointer;" @click="shangchuan(scope.row,1)">点击上传</span>-->
              <!--  <span v-else style="color: #0000FF;cursor: pointer;" @click="chakan(scope.row,1)">点击查看</span>-->
              <!--</template>-->

              <!--<template v-slot:invoice= "{ scope }">-->
              <!--  <span v-if="scope.row.invoice == ''" style="cursor: pointer;color: #0000FF;" @click="shangchuan(scope.row,2)">-->
              <!--  点击上传-->
              <!--  </span>-->
              <!--  <span v-else style="cursor: pointer;color: #0000FF;" @click="chakan(scope.row,2)">-->
              <!--    点击查看-->
              <!--  </span>-->
              <!--</template>-->

              <!-- 不使用数据可不接收scope值 -->
              <!--<template v-slot:operationSlot= "{ scope }">-->
              <!--  <el-popconfirm-->
              <!--      title="确认到账吗?"-->
              <!--      @confirm="confirm(scope.row)"-->
              <!--  >-->
              <!--    <el-link slot="reference" :underline="false" type="danger">确认到账</el-link>-->
              <!--  </el-popconfirm>-->
              <!--</template>-->
            </my-table>

          </div>
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>

    <el-dialog
      center
      :title="title"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="guanbi">
      <div style="cursor: pointer;text-align: center;">
        <div class="upload_bg_shouchi">
          <el-upload
            :action="action"
            :headers="headers"
            :on-success="successUpload"
            :limit="1"
            :file-list="fileList"
            :class="{hide_box: handupload_btn}"
            :on-change="handchange"
            list-type="picture-card"
            :on-preview="handPreview"
            :on-remove="handhandleRemove">
            <!--<i class="el-icon-plus"></i>-->
            <img style="margin-top: 20px;" src="@/assets/images/home/upload_anniu.png" alt="">
            <div style="font-size: 12px;font-weight: 500;color: #ff9b05;margin-top: 10px;">上传图片</div>
          </el-upload>
          <el-dialog :visible.sync="handdialogVisible">
            <img width="100%" :src="handdialogImageUrl" alt="">
          </el-dialog>
        </div>
      </div>
      <div slot="footer">
        <el-button
          @click="dialogVisible = false">取消
        </el-button>
        <el-button
          style="margin-left: 20px;"
          type="primary"
          @click="save">确定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
      center
      :title="title"
      :visible.sync="examine"
      width="50%"
      :before-close="guanbi">
      <div style="cursor: pointer;text-align: center;">
        <img :src="image" style="width: 100%;">
      </div>
      <div slot="footer">
        <el-button
          @click="examine = false">取消
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

// 权限
import {ShowTable} from '@/utils'

// 引入的接口
import {application_invoice, application_serial_number, otherapplication_record} from "@/api/yunli";

// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'


export default {
  // 组件生效
  components: {
    PackageSortElement,
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        // {
        //   type: "selection",
        //   isShow: true
        // },
        // {
        //   label: "类型",
        //   // type: "index",
        //   // width: "70"
        //   prop: "type",
        //   isShow: true,
        //   fixed: 'left'
        // },
        // {
        //   label: "服务商",
        //   // type: "index",
        //   // width: "70"
        //   prop: "facilitator_name",
        //   isShow: true,
        // },
        // {
        //   label: "提交时间 ",
        //   prop: "times",
        //   isShow: true
        // },
        // {
        //   label: "客户名称",
        //   prop: "facilitator_addres",
        //   isShow: true
        // },
        // {
        //   label: "支付方式",
        //   prop: "pay_mode",
        //   isShow: true
        // },
        // {
        //   label: "申请人",
        //   prop: "applicant",
        //   isShow: true
        // },
        // {
        //   label: "审核人",
        //   prop: "auditor",
        //   isShow: true
        // },
        // {
        //   label: "审核时间",
        //   prop: "audit_time",
        //   isShow: true
        // },
        // {
        //   label: "状态",
        //   prop: "status",
        //   slot: 'status',
        //   isShow: true
        // },
        // {
        //   label: "汇款单/流水号",
        //   prop: "serial_number",
        //   slot: 'serial_number',
        //   isShow: true
        // },
        // {
        //   label: "发票",
        //   prop: "invoice",
        //   slot: 'invoice',
        //   isShow: true
        // },
        // // {
        // //   label: "操作",
        // //   width: "150",
        // //   slot: "operationSlot",
        // //   isShow: true,
        // //   fixed: 'right'
        // // },

        {
          type: "selection",
          isShow: true
        },
        {
          label: "编号",
          prop: "id",
          isShow: true,
          fixed: 'left'
        },
        {
          label: "单号",
          prop: "odd",
          isShow: true
        },
        {
          label: "购买资源类型",
          prop: "resource_name",
          isShow: true
        },
        {
          label: "支付状态",
          prop: "paid_status",
          slot: 'paid_status',
          isShow: true
        },
        {
          label: "支付方式",
          prop: "pay_mode",
          isShow: true
        },
        {
          label: "流水号",
          prop: "trade_no",
          isShow: true
        },
        {
          label: "支付价格",
          prop: "price",
          isShow: true
        },
        {
          label: "购买数量",
          prop: "amount",
          isShow: true
        },
        {
          label: "支付时间",
          prop: "pay_time",
          isShow: true
        },
        {
          label: "创建时间",
          prop: "time",
          isShow: true
        },
      ],

      // 列表设置弹窗
      ListSetupShow:false,

      //上传
      dialogVisible:false,

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      //汇款单
      handdialogImageUrl:'',
      handdialogVisible:false,
      handupload_btn:false,
      fileList:[],

      //弹窗名
      title:'',
      //是发票 还是汇款单
      type:'',
      id:'',

      serial_number:'',
      invoice:'',
      examine:false,
      image:'',

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      otherapplication_record(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    //确认到账
    confirm(row){
      console.log('确认到账',row)
    },

    // 点击上传
    shangchuan(row,number){
      this.title = '上传';
      this.dialogVisible = true;
      this.type = number;
      this.id = row.id;
    },
    // 点击查看
    chakan(row,number){
      console.log(row)
      this.title = '查看';
      // this.dialogVisible = true;
      // this.handupload_btn = true;
      if(number == 1){
        // var data = {
        //   url: row.serial_number
        // }
        // this.fileList.push(data);
        this.examine = true;
        this.image = row.serial_number;
      }else {
        // var data1 = {
        //   url: row.invoice
        // }
        // this.fileList.push(data1);
        this.examine = true;
        this.image = row.invoice;
      }

    },
    //关闭上传弹窗
    guanbi(){
      this.dialogVisible = false;
    },

    // 汇款单
    handchange(){
      this.handupload_btn = true;
    },
    handhandleRemove(){
      this.fileList = [];
      this.handupload_btn = false;
    },
    handPreview(file){
      this.handdialogImageUrl = file.url;
      this.handdialogVisible = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      if(this.type == '1'){
        this.serial_number = file.data.urls;
      }else {
        this.invoice = file.data.urls;
      }
    },

    //点击保存
    save(){
      if(this.type == '1'){
        let data = {
          serial_number: this.serial_number
        }
        application_serial_number(this.id,data).then(res => {
          if(res.code == 200){
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.getList();
          }else {
            this.$message.error(res.msg)
          }
        })
      }else {
        let data = {
          invoice: this.invoice
        }
        application_invoice(this.id,data).then(res => {
          if(res.code == 200){
            this.$message.success(res.msg);
            this.dialogVisible = false;
            this.getList();
          }else {
            this.$message.error(res.msg)
          }
        })
      }
    },


  }

}
</script>

<style lang="scss" scoped>
.hide_box /deep/ .el-upload--picture-card {
  display: none;
}
</style>
